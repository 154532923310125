<template>
	<div class="task-progress position-relative w-100 h-100 d-flex flex-column">
		<div class="controls position-relative w-100 p-4 d-flex align-items-center">
			<div class="form-group position-relative mb-0 d-flex align-items-center">
				<label for="task" class="task-label font-weight-bold mb-0 mr-3">Task:</label>
				<select id="task" class="form-control position-relative"
				        v-model="task">
					<option disabled hidden value=""></option>
					<option v-for="task in tasks" v-bind:value="task">{{task.taskNo}}</option>
				</select>
			</div>
			<h5 class="position-relative mb-0 ml-5 font-weight-bold">Status: {{selectedTask.status}}</h5>
			<div class="control-buttons position-relative ml-auto d-flex">
				<button type="button" class="button btn btn-custom-light w-auto d-flex align-items-center overflow-hidden"
				        v-on:click="exportCSV">
					<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'file-csv']"></font-awesome-icon>
					<span class="name font-weight-bold text-truncate">Export CSV</span>
				</button>
			</div>
		</div>
		<gantt-chart v-bind:chart-data="chartData"
		             v-bind:data-changed="dataChanged"
		             v-bind:start-date="selectedTask.createdAt"
		             v-bind:style="{padding: '0 16px 0 16px'}"></gantt-chart>
	</div>
</template>

<script>
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import FileSaver from "file-saver";
import { toCsv } from "@/worker/file-export.worker";
import GanttChart from "@/components/functional/chart/GanttChart";

export default {
	name: "ProgressReport",
	
	components: {
		GanttChart
	},
	
	created() {
		if (this.tasks.length > 0) {
			this.selectedTask = this.tasks[0];
			this.generateData(this.tasks[0]);
		}
	},
	
	data() {
		return {
			dataChanged: false,
			selectedTask: null,
			chartData: {
				labels: ["Lead", "Opportunity", "Sales", "Campaign"],
				datasets: [
					// base
					{
						label: '',
						data: [],
						backgroundColor: "transparent",
						borderWidth: 0,
						maxBarThickness: 30,
						//minBarLength: 1,
						barPercentage: 0.4,
						//order: 2,
					},
					{
						label: 'Lead',
						data: [],
						backgroundColor: "#496bb3",
						borderWidth: 1,
						maxBarThickness: 30,
						barPercentage: 0.4,
					},
					{
						label: 'Opportunity',
						data: [],
						backgroundColor: "#496bb3",
						borderWidth: 1,
						maxBarThickness: 30,
						barPercentage: 0.4,
					},
					{
						label: 'Sales',
						data: [],
						backgroundColor: "#496bb3",
						borderWidth: 1,
						maxBarThickness: 30,
						barPercentage: 0.4,
					},
					{
						label: 'Campaign',
						data: [],
						backgroundColor: "#496bb3",
						borderWidth: 1,
						maxBarThickness: 30,
						barPercentage: 0.4,
					},
				],
			}
		}
	},
	
	computed: {
		tasks() {
			return this.$store.state.contents.tasks;
		},
		
		task: {
			set(task) {
				this.selectedTask = task;
				this.generateData(task);
				this.dataChanged = !this.dataChanged;
			},
			get() {
				return this.selectedTask;
			}
		}
	},
	
	methods: {
		generateData(task) {
			const start = new Date(task.createdAt);
			const leadPeriod = this.leadDays(start, task.quotationRequests);
			const opportunityDays = this.opportunityDays(start, task.supplierQuotations);
			const salesDays = this.salesDays(start, task.salesOrders);
			const campaignDays = this.campaignDays(start, task.campaigns);
			const opportunityPeriod = opportunityDays - leadPeriod > 0 ? opportunityDays - leadPeriod : 0;
			const salesPeriod = salesDays - opportunityDays > 0 ? salesDays - opportunityDays : 0;
			const campaignPeriod = campaignDays - salesDays > 0 ? campaignDays - salesDays : 0;
			this.chartData.datasets[0].data = [0, leadPeriod, leadPeriod + opportunityPeriod, leadPeriod + opportunityPeriod + salesPeriod];
			this.chartData.datasets[1].data = [leadPeriod, 0, 0, 0];
			this.chartData.datasets[2].data = [0, opportunityPeriod, 0, 0];
			this.chartData.datasets[3].data = [0, 0, salesPeriod, 0];
			this.chartData.datasets[4].data = [0, 0, 0, campaignPeriod];
		},
		
		leadDays(start, quotationRequests) {
			return quotationRequests.reduce((acc, id) => {
				const request = this.$store.state.contents.quotationRequests.find(({ _id }) => _id === id);
				if (request) {
					const issuedAt = request.issuedAt ? new Date(request.issuedAt) : new Date(0);
					if (start < issuedAt) {
						const days = differenceInCalendarDays(issuedAt, start);
						if (days > acc) return days;
					}
				}
				return acc;
			}, 0);
		},
		
		opportunityDays(start, supplierQuotations) {
			return supplierQuotations.reduce((acc, id) => {
				const quote = this.$store.state.contents.supplierQuotations.find(({ _id }) => _id === id);
				if (quote) {
					// supplier quote must contain receivedAt
					const receivedAt = new Date(quote.receivedAt);
					if (start < receivedAt) {
						const days = differenceInCalendarDays(receivedAt, start);
						if (days > acc) return days;
					}
				}
				return acc;
			}, 0);
		},
		
		salesDays(start, salesOrders) {
			return salesOrders.reduce((acc, id) => {
				const order = this.$store.state.contents.salesOrders.find(({ _id }) => _id === id);
				if (order) {
					const issuedAt = order.issuedAt ? new Date(order.issuedAt) : new Date(0);
					if (start < issuedAt) {
						const days = differenceInCalendarDays(issuedAt, start);
						if (days > acc) return days;
					}
				}
				return acc;
			}, 0);
		},
		
		campaignDays(start, campaigns) {
			return campaigns.reduce((acc, id) => {
				const campaign = this.$store.state.contents.campaigns.find(({ _id }) => _id === id);
				if (campaign) {
					// campaign must contain endAt
					const endAt = new Date(campaign.endAt);
					if (start < endAt) {
						const days = differenceInCalendarDays(endAt, start);
						if (days > acc) return days;
					}
				}
				return acc;
			}, 0);
		},
		
		async exportCSV() {
			const csvData = {
				stage: ["Lead", "Opportunity", "Sales", "Campaign"],
				days: [
					this.chartData.datasets[1].data[0],
					this.chartData.datasets[2].data[1],
					this.chartData.datasets[3].data[2],
					this.chartData.datasets[4].data[3],
				]
			}
			const file = await toCsv(csvData);
			FileSaver.saveAs(file, `ProgressReport.csv`);
		},
	}
}
</script>

<style lang="scss" scoped>
.control-buttons {
	min-width: 40px;
}

.task-label {
	font-size: 20px;
}
</style>
