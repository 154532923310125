<script>
import { HorizontalBar } from 'vue-chartjs'
import format from "date-fns/format";

export default {
	name: "GanttChart",
	
	extends: HorizontalBar,
	
	props: {
		chartData: {
			type: Object,
			required: true,
		},
		dataChanged: {
			type: Boolean,
			required: true
		},
		startDate: {
			type: String,
			required: true
		}
	},
	
	mounted () {
		this.renderChart(this.chartData, this.options)
	},
	
	data() {
		return {
			options: {
				responsive: true,
				maintainAspectRatio: false,
				spanGaps: true,
				onClick: this.clickHandler,
				legend: {
					display: false
				},
				scales: {
					xAxes: [{
						stacked: true,
						ticks: {
							fontSize: 16,
							fontColor: "#39538a",
							autoSkip: true,
							beginAtZero: true,
							maxTicksLimit: 10,
							//maxRotation: 60,
							//minRotation: 60,
							// use arrow function to pass the value, traditional function "this" is scoped to the chart
							// instance
							callback: (value) => {
								const start = new Date(this.startDate).getTime();
								const date = new Date(start + value * 8.64e+7);
								return format(date, "MMM do, yy");
							}
						},
					}],
					yAxes: [{
						stacked: true,
						ticks: {
							fontSize: 20,
							fontColor: "#39538a",
						}
					}]
				},
				tooltips: {
					enabled: true,
					callbacks: {
						label: function(tooltipItem, data) {
							if (tooltipItem.datasetIndex - tooltipItem.index === 1) return `${tooltipItem.value} Days`;
						}
					}
				},
			}
		}
	},
	
	watch: {
		dataChanged() {
			this.renderChart(this.chartData, this.options);
		}
	},
}
</script>

<style scoped>

</style>